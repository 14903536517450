export interface ICountiesState {
  counties: ICounties[];
  states: IStateStore[];
  areas: IAreaStore[];
}

let countiesState: ICountiesState = {
  counties: [],
  states: [],
  areas: [],
};

export default countiesState;

export interface ICounties {
  name: string;
  codeSerpro: number;
  codeIbge: number;
  areaInfluenciaNome: string;
  regiaoOperacionalNome: string;
  capital: boolean;
  author: string;
  id?: number;
  state: string;
}

export interface IStateStore {
  id: number;
  name: string;
  sigla: string;
}

export interface IAreaStore {
  id: number;
  name: string;
  code: number;
}

export interface ICityLocal {
  id: number | null;
  name: string;
  codeSerpro: number | null;
  codeIbge: string;
  capital: { label: string; value: boolean | null };
  state: { label: string; value: number | null };
  area: { label: string; value: number | null };
}
