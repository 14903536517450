import { $themeConfig } from "@themeConfig";

export interface IParamConfig {
  ABRACAF_CODIGO_PERFIL_ADMIN: string | null;
  ABRACAF_CODIGO_PERFIL_GRUPO: string | null;
  ABRACAF_CODIGO_PERFIL_LOJA: string | null;
  ABRACAF_CODIGO_PERFIL_REGIONAL: string | null;
  EMP_ASSOCIACAO_GRUPO_ECONOMICO: string | null;
  EMP_FABRICANTE: string | null;
  EMP_NOME_ASSOCIACAO_POR_EXTENSO: string | null;
  EMP_RELATORIOS_COR: string | null;
  GRUPO_EMPRESA_TITLE: string | null;
}

export interface IAppConfigState {
  layout: {
    isRTL: boolean;
    skin: string;
    routerTransition: string;
    type: string;
    contentWidth: string;
    menu: {
      hidden: boolean;
      isCollapsed: boolean;
    };
    navbar: {
      type: string;
      backgroundColor: string;
    };
    footer: {
      type: string;
    };
  };
  params: IParamConfig;
}

const localParams = localStorage.getItem("params");
let params: IParamConfig = {
  ABRACAF_CODIGO_PERFIL_ADMIN: null,
  ABRACAF_CODIGO_PERFIL_GRUPO: null,
  ABRACAF_CODIGO_PERFIL_LOJA: null,
  ABRACAF_CODIGO_PERFIL_REGIONAL: null,
  EMP_ASSOCIACAO_GRUPO_ECONOMICO: null,
  EMP_FABRICANTE: null,
  EMP_NOME_ASSOCIACAO_POR_EXTENSO: null,
  EMP_RELATORIOS_COR: null,
  GRUPO_EMPRESA_TITLE: null,
};

if (typeof localParams == "string" && localParams != "") {
  params = JSON.parse(localParams);
}
let appConfigState: IAppConfigState = {
  layout: {
    isRTL: $themeConfig.layout.isRTL,
    skin: localStorage.getItem("vuexy-skin") || $themeConfig.layout.skin,
    routerTransition: $themeConfig.layout.routerTransition,
    type: $themeConfig.layout.type,
    contentWidth: $themeConfig.layout.contentWidth,
    menu: {
      hidden: $themeConfig.layout.menu.hidden,
      isCollapsed: $themeConfig.layout.menu.isCollapsed,
    },
    navbar: {
      type: $themeConfig.layout.navbar.type,
      backgroundColor: $themeConfig.layout.navbar.backgroundColor,
    },
    footer: {
      type: $themeConfig.layout.footer.type,
    },
  },
  params: params,
};

export default appConfigState;
