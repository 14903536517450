import AuthService from "@core/services/auth/authService";
import defaultConfig from "./userDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IUserService,
  IResponseGetLoggedUser,
  TUserConfig,
  IUpdateUser,
  IResponseUpdateUser,
  IResponseGetById,
  IBodyUsers,
  IResponseList,
  ICreateUser,
  IResponseCreateUser,
  IFieldPermissions,
  IResponseGetDailyReportTypes,
} from "../interfaces/user/IUserService";

export default class UserService extends AuthService implements IUserService {
  serviceUserConfig: TUserConfig;
  constructor(axiosIns: AxiosInstance, userOverrideConfig: Object) {
    super(axiosIns, userOverrideConfig);
    this.serviceUserConfig = { ...defaultConfig, ...userOverrideConfig };
  }

  /**
   * Essa rota consulta as informações do usuário que está logado de acordo com o token
   * @returns {Promise<IResponseGetLoggedUser>}
   */
  requestGetLoggedUser(): Promise<IResponseGetLoggedUser> {
    return this.axiosIns.get(this.serviceUserConfig.getLoggedUserEndpoint);
  }

  requestGetById(id: string): Promise<IResponseGetById> {
    return this.axiosIns.get(
      this.serviceUserConfig.getByIdUserEndpoint + `?usuarioId=${id}`
    );
  }

  requestList(args: IBodyUsers): Promise<IResponseList> {
    return this.axiosIns.post(
      this.serviceUserConfig.getListUsersEndpoint +
        `?paginar=${args.paginate ? "true" : "false"}`,
      args
    );
  }

  requestCreate(user: ICreateUser): Promise<IResponseCreateUser> {
    return this.axiosIns.post(this.serviceUserConfig.createUsersEndpoint, user);
  }

  requestUpdate(user: IUpdateUser): Promise<IResponseUpdateUser> {
    return this.axiosIns.put(this.serviceUserConfig.updateUserEndpoint, user);
  }

  requestGetDailyReportTypes(): Promise<IResponseGetDailyReportTypes> {
    return this.axiosIns.get(
      this.serviceUserConfig.getDailyReportTypesEndpoint
    );
  }

  // Busines Rules
  getVisualizationPermissions(profile: string): IFieldPermissions {
    let show: IFieldPermissions = {
      economicGroup: false,
      businessGroup: false,
      operationalRegion: false,
      dealership: false,
    };
    let params = JSON.parse(localStorage.getItem("params") || "");

    if (
      profile != "" &&
      (params.ABRACAF_CODIGO_PERFIL_GRUPO.includes(profile) ||
        params.ABRACAF_CODIGO_PERFIL_LOJA.includes(profile))
    ) {
      show.businessGroup = true;
      show.dealership = true;
      show.economicGroup = true;
    }

    if (
      profile != "" &&
      params.ABRACAF_CODIGO_PERFIL_REGIONAL.includes(profile)
    ) {
      show.operationalRegion = true;
    }

    return show;
  }

  getRequiredPermissions(profile: string): IFieldPermissions {
    let required: IFieldPermissions = {
      operationalRegion: false,
      dealership: false,
    };
    let params = JSON.parse(localStorage.getItem("params") || "");

    if (
      profile != "" &&
      (params.ABRACAF_CODIGO_PERFIL_GRUPO.includes(profile) ||
        params.ABRACAF_CODIGO_PERFIL_LOJA.includes(profile))
    ) {
      required.dealership = true;
    }

    if (
      profile != "" &&
      params.ABRACAF_CODIGO_PERFIL_REGIONAL.includes(profile)
    ) {
      required.operationalRegion = true;
    }

    return required;
  }
}
