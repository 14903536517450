import AuthService from "@core/services/auth/authService";
import defaultConfig from "./groupDefaultConfig";
import { AxiosInstance } from "axios";
import {
  IGroupService,
  IBodyCreateGroup,
  TResponseCreateUpdateDeleteGroup,
  IBodyUpdateGroup,
  IResponseListGroup,
  IResponseGetGroup,
  TGroupConfig,
  IBodyListGroup,
} from "@core/services/interfaces/business/group/IGroupService";

export default class GroupService extends AuthService implements IGroupService {
  serviceGroupConfig: TGroupConfig;
  constructor(axiosIns: AxiosInstance, overrideConfig: Object) {
    super(axiosIns, overrideConfig);
    this.serviceGroupConfig = { ...defaultConfig, ...overrideConfig };
  }

  /**
   * Requisição para criar grupo
   */
  requestCreateGroup(
    group: IBodyCreateGroup
  ): Promise<TResponseCreateUpdateDeleteGroup> {
    return this.axiosIns.post(
      this.serviceGroupConfig.createGroupEndpoint,
      group
    );
  }

  /**
   * Requisição para atualizar grupo
   */
  requestUpdateGroup(
    group: IBodyUpdateGroup
  ): Promise<TResponseCreateUpdateDeleteGroup> {
    return this.axiosIns.put(
      this.serviceGroupConfig.updateGroupEndpoint,
      group
    );
  }

  /**
   * Requisição para apagar grupo
   * @param  {number} groupId
   * @returns {Promisse}
   */
  requestDeleteGroup(
    groupId: number
  ): Promise<TResponseCreateUpdateDeleteGroup> {
    return this.axiosIns.delete(
      this.serviceGroupConfig.deleteGroupEndpoint + "?id=" + groupId
    );
  }

  /**
   * Requisição para listar os grupos
   * @param  {IBodyListGroup} args
   * @returns {Promisse}
   */
  requestListGroup(args: IBodyListGroup): Promise<IResponseListGroup> {
    return this.axiosIns.post(
      this.serviceGroupConfig.listGroupEndpoint +
        `?paginar=${args.paginate ? args.paginate : false}`,
      args
    );
  }

  /**
   * Requisição para consultar por ID um grupo
   * @param  {number} groupId
   * @returns {Promisse}
   */
  requestGetByIdGroup(groupId: number): Promise<IResponseGetGroup> {
    return this.axiosIns.get(
      this.serviceGroupConfig.getByIdGroupEndpoint + "?id=" + groupId
    );
  }
}
