import { IFieldControl } from "@core/services/interfaces/business/dealerships/IDealershipsService";

const fieldControl: IFieldControl = {
  RazaoSocial: {
    required: true,
    optional: false,
  },
  NomeFantasia: {
    required: true,
    optional: false,
  },
  CNPJ: {
    required: true,
    optional: false,
  },
  Email: {
    required: false,
    optional: true,
  },
  Telefone: {
    required: false,
    optional: true,
  },
  Site: {
    required: false,
    optional: true,
  },
  Codigo: {
    required: false,
    optional: true,
  },
  CodigoFaturamento: {
    required: true,
    optional: false,
  },
  Matriz: {
    required: false,
    optional: true,
  },
  GrupoEmpresaId: {
    required: false,
    optional: true,
  },
  CEP: {
    required: true,
    optional: false,
  },
  EstadoId: {
    required: true,
    optional: false,
  },
  MunicipioId: {
    required: true,
    optional: false,
  },
  Bairro: {
    required: false,
    optional: true,
  },
  Numero: {
    required: false,
    optional: true,
  },
  Complemento: {
    required: false,
    optional: true,
  },
  Logradouro: {
    required: true,
    optional: false,
  },
  Coordenadas: {
    required: true,
    optional: false,
  },
  Cadastrada: {
    required: true,
    optional: false,
  },
  Associada: {
    required: true,
    optional: false,
  },
  PendenciaFinanceira: {
    required: false,
    optional: true,
  },
  Status: {
    required: true,
    optional: false,
  },
  DataInauguracao: {
    required: false,
    optional: true,
  },
  DataNomeacao: {
    required: false,
    optional: true,
  },
  DataCancelamento: {
    required: false,
    optional: true,
  },
  InscricaoEstadual: {
    required: false,
    optional: true,
  },
  InscricaoMunicipal: {
    required: false,
    optional: true,
  },
  Montadora: {
    required: true,
    optional: false,
  },
  Observacoes: {
    required: false,
    optional: true,
  },
  Endereco: {
    required: true,
    optional: false,
  },
};

export default fieldControl;
